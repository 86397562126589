import { PredepositStats, PredepositUser } from '@/state/predeposit/types'
import { formatUnits } from 'ethers/lib/utils'

export type WithdrawableSummary = {
  totalWithdrawableUSD: number
  withdrawable: boolean
}

export function userPredepositWithdrawableSummary({
  predepositStats,
  thresholdUSD,
  predepositUser,
  tokens,
}: {
  thresholdUSD: number
  predepositStats: PredepositStats | undefined
  predepositUser: PredepositUser | undefined
  tokens: { address: string; decimals: number }[]
}): WithdrawableSummary | undefined {
  if (!predepositStats || !predepositUser) {
    return undefined
  }

  const { ratesUsd } = predepositStats
  const { stakes } = predepositUser

  let totalWithdrawableUSD = 0
  for (const [asset, balance] of Object.entries(stakes)) {
    const decimals = tokens.find((t) => t.address === asset)?.decimals
    if (!decimals) {
      continue
    }
    const rateUSD = ratesUsd[asset]
    if (rateUSD === undefined) {
      continue
    }

    const amount = Number(formatUnits(balance, decimals))

    totalWithdrawableUSD += rateUSD * amount
  }

  return {
    totalWithdrawableUSD,
    withdrawable: totalWithdrawableUSD >= thresholdUSD,
  }
}
