import { Box } from '@/swell-ui/Box'
import styled, { css } from 'styled-components'
import { L2BridgeActionSelect } from './SwellchainButtons'
import { L2PageViewInputs } from './hooks'
import { ReactNode } from 'react'
import { SuperbridgeView } from './views/SuperbridgeView'
import { PredepositWithdrawView } from './views/PredepositWithdrawView'
import {
  PredepositInputs,
  PredepositTokensSummary,
  PredepositWithdrawalSummary,
  PredepositWithdrawController,
} from './types'
import { L2BridgeBreakpoints } from './styles'
import { PredepositUser } from '@/state/predeposit/types'
import { PreparedWithdrawFromPredeposit } from './swellchainCalls'
import { PredepositCalls, PredepositTokens } from '@/state/predeposit/hooks'
import {
  PreparedNucleusApproveAssetForDeposit,
  PreparedNucleusDeposit,
} from '@/components/Nucleus/nucleusCalls'
import { NucleusBalances } from '@/state/nucleusVault/types'

export function SwellchainWidget({
  viewInputs,
  predepositCalls,
  predepositWithdrawalSummary,
  predepositInputs,
  predepositTokens,
  predepositTokensSummary,
  predepositUser,
  preparedWithdrawFromPredeposit,
  preparedApproveAssetForDeposit,
  preparedEarnEthDeposit: preparedDeposit,
  controller,
  earnEthBalances,
}: {
  viewInputs: L2PageViewInputs
  predepositWithdrawalSummary: PredepositWithdrawalSummary
  predepositUser: PredepositUser | undefined
  preparedWithdrawFromPredeposit: PreparedWithdrawFromPredeposit
  predepositInputs: PredepositInputs
  predepositCalls: PredepositCalls
  predepositTokens: PredepositTokens
  predepositTokensSummary: PredepositTokensSummary
  preparedApproveAssetForDeposit: PreparedNucleusApproveAssetForDeposit
  preparedEarnEthDeposit: PreparedNucleusDeposit
  controller: PredepositWithdrawController
  earnEthBalances: NucleusBalances | undefined
}) {
  const { isSuperbridgeView, isWithdrawView } = viewInputs

  let tabContent: ReactNode
  if (isSuperbridgeView) {
    tabContent = null
  } else if (isWithdrawView) {
    tabContent = (
      <PredepositWithdrawView
        predepositWithdrawalSummary={predepositWithdrawalSummary}
        predepositUser={predepositUser}
        preparedWithdrawFromPredeposit={preparedWithdrawFromPredeposit}
        predepositInputs={predepositInputs}
        predepositCalls={predepositCalls}
        predepositTokens={predepositTokens}
        predepositTokensSummary={predepositTokensSummary}
        preparedApproveAssetForDeposit={preparedApproveAssetForDeposit}
        preparedDeposit={preparedDeposit}
        controller={controller}
        balances={earnEthBalances}
      />
    )
  }

  return (
    <Layout>
      <L2BridgeActionSelect viewInputs={viewInputs} />
      <WidgetPositioner>
        <WidgetBox>
          {tabContent}
          <div
            className="sb-pos"
            style={{
              opacity: isSuperbridgeView ? 1 : 0,
              pointerEvents: isSuperbridgeView ? 'auto' : 'none',
            }}
          >
            <SuperbridgeView />
          </div>
        </WidgetBox>
      </WidgetPositioner>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  width: 100%;
  align-self: stretch;
`

// not yet

const layoutMobile = css`
  padding: 24px 32px;
`
const layoutDesktop = css``

const WidgetPositioner = styled.div`
  width: 100%;
  height: 694px;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const WidgetBox = styled(Box)`
  width: 100%;
  min-height: 694px;

  ${layoutMobile}
  @media (min-width: ${L2BridgeBreakpoints.mobile}px) {
    ${layoutDesktop}
  }

  border: 1px solid transparent;
  border-radius: 20px;
  background: var(--Swell-Oceanblue-150, #010508);
  border: 0.8px solid rgba(164, 171, 241, 0.2);
  background: var(--Swell-Oceanblue-150, #010508);

  display: flex;
  flex-flow: column nowrap;

  position: relative;
  .sb-pos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
  overflow: hidden;
`
