import { BigNumber } from 'ethers'
import {
  StaticAirdropV2Resp,
  StaticAirdropV2RespRaw,
  StaticAirdropV2Result,
  StaticAirdropV2RswellResp,
  StaticAirdropV2RswellResult,
  StaticAirdropV2SwellResp,
} from './types'

export function parseRawStaticAirdropV2Resp(
  respRaw: StaticAirdropV2RespRaw
): StaticAirdropV2Resp {
  function parseRswell(): StaticAirdropV2RswellResp | null {
    if (!respRaw.rswell) {
      return null
    }

    return {
      airdrop: {
        cumulativeAmount: BigNumber.from(
          respRaw.rswell.airdrop.cumulative_amount
        ),
        meta: respRaw.rswell.airdrop.meta,
        proofs: respRaw.rswell.airdrop.proofs,
      },
      meta: {
        merkleRoot: respRaw.rswell.meta.merkle_root,
        totalCumulative: BigNumber.from(respRaw.rswell.meta.total_cumulative),
        newVested: BigNumber.from(respRaw.rswell.meta.new_vested),
      },
    }
  }

  function parseSwell(): StaticAirdropV2SwellResp | null {
    if (!respRaw.swell) {
      return null
    }

    return {
      airdrop: {
        cumulativeAmount: BigNumber.from(
          respRaw.swell.airdrop.cumulative_amount
        ),
        meta: {
          loyaltyAmount: BigNumber.from(
            respRaw.swell.airdrop.meta.loyalty_amount
          ),
          newAmount: BigNumber.from(respRaw.swell.airdrop.meta.new_amount),
          pearlAmount: respRaw.swell.airdrop.meta.pearl_amount,
          vestingTier: respRaw.swell.airdrop.meta.vesting_tier,
          isSybil: respRaw.swell.airdrop.meta.is_sybil,
          selectedOption2: respRaw.swell.airdrop.meta.selected_option_2,
          totalAmount: BigNumber.from(respRaw.swell.airdrop.meta.total_amount),
        },
        proofs: respRaw.swell.airdrop.proofs,
      },
      meta: {
        merkleRoot: respRaw.swell.meta.merkle_root,
        totalCumulative: BigNumber.from(respRaw.swell.meta.total_cumulative),
        newVested: BigNumber.from(respRaw.swell.meta.new_vested),
        symbol: respRaw.swell.meta.symbol,
        version: respRaw.swell.meta.version,
      },
    }
  }

  return {
    rswell: parseRswell(),
    swell: parseSwell(),
  }
}

export function makeStaticAirdropV2Result(
  raw: StaticAirdropV2Resp
): StaticAirdropV2Result {
  if (!raw.swell) {
    return {
      exists: false,
      pearls: 0,
      isSybil: false,
    }
  }
  if (raw.swell.airdrop.meta.isSybil) {
    return {
      exists: false,
      pearls: raw.swell.airdrop.meta.pearlAmount,
      isSybil: true,
    }
  }

  function parseRswell(): StaticAirdropV2RswellResult {
    if (!raw.rswell) {
      return {
        exists: false,
      }
    }

    return {
      exists: true,
      data: {
        cumulativeAmount: raw.rswell.airdrop.cumulativeAmount,
        merkleRoot: raw.rswell.meta.merkleRoot,
        proofsHex: raw.rswell.airdrop.proofs,
      },
    }
  }

  return {
    exists: true,
    data: {
      cumulativeAmount: raw.swell.airdrop.cumulativeAmount,
      loyaltyAmount: raw.swell.airdrop.meta.loyaltyAmount,
      merkleRoot: raw.swell.meta.merkleRoot,
      proofsHex: raw.swell.airdrop.proofs,
      rswell: parseRswell(),
      selectedOption2: Boolean(raw.swell.airdrop.meta.selectedOption2),
      totalAmount: raw.swell.airdrop.meta.totalAmount,
      vestingTier: raw.swell.airdrop.meta.vestingTier,
    },
    isSybil: false,
    pearls: raw.swell.airdrop.meta.pearlAmount,
  }
}
