import styled from 'styled-components'
import { YearnBalances, YearnVaultStats } from '@/state/yearnVault/types'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { labelStyle, valueStyle } from './common'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Tooltip } from '@/swell-ui/Tooltip'
import { YearnAeraVault, YearnAeraVaultPoints } from '@/types/yearnAera'
import {
  makeYearnHoldingsSummary,
  makeYearnPointsSummary,
  makeYearnVaultSummary,
} from '../yearnAeraFormatting'
import { YearnHoldingsSummary, YearnPointsSummary } from '../types'
import { Token } from '@/types/tokens'
import { PriceReading } from '@/state/prices/types'
import { URI_SYMBIOTIC_LOGO } from '@/constants/logo'

const ROW_HEIGHT = '25.6px'
const ICON_SIZE_PX = 28

export function YearnAeraStatistics({
  vaultStats,
  balances,
  vault,
  depositAssetUsdRate,
  isBtc,
  vaultPoints,
}: {
  isBtc?: boolean

  vault: YearnAeraVault
  vaultPoints: YearnAeraVaultPoints | undefined
  balances: YearnBalances | undefined

  vaultStats: YearnVaultStats | undefined
  depositAssetUsdRate: PriceReading | undefined
}) {
  const { account } = useSwellWeb3()
  const noAccount = !account
  const vaultSummary = makeYearnVaultSummary({
    depositAssetUsdRate,
    vault,
    vaultStats,
  })
  const pointsSummary = makeYearnPointsSummary({ vaultPoints })
  const holdingsSummary = makeYearnHoldingsSummary({
    account,
    balances,
    depositAssetUsdRate,
    vault,
    isBtc,
  })

  const totalDepositorsNode = (
    <AsyncDiv loading={!vaultSummary.totalDepositors}>
      {() => <span className="value">{vaultSummary.totalDepositors}</span>}
    </AsyncDiv>
  )
  const tvlNode = (
    <AsyncDiv loading={!vaultSummary.vaultTvl}>
      {() => <span className="value">{vaultSummary.vaultTvl}</span>}
    </AsyncDiv>
  )

  let aprNode = (
    <AsyncDiv loading={!vaultSummary.vaultApr}>
      {() => <span className="value">{vaultSummary.vaultApr}</span>}
    </AsyncDiv>
  )
  if (vaultSummary.vaultAprIndicative) {
    aprNode = (
      <AsyncDiv loading={!vaultSummary.vaultApr}>
        {() => (
          <Tooltip title="Yield to come when restaking protocols go live">
            <span className="value">{vaultSummary.vaultApr}</span>
          </Tooltip>
        )}
      </AsyncDiv>
    )
  }

  let feesNode = (
    <AsyncDiv loading={!vaultSummary.vaultFees}>
      {() => <span className="value">{vaultSummary.vaultFees}</span>}
    </AsyncDiv>
  )
  if (vaultSummary.vaultFeesWillBe) {
    feesNode = (
      <Tooltip
        title={`Fee will switch to ${vaultSummary.vaultFeesWillBe} when yields stabilize`}
      >
        <span className="value">TBD</span>
      </Tooltip>
    )
  }

  return (
    <Layout className={noAccount ? 'no-account' : ''}>
      <div>
        <span className="label">Total Value Locked</span>
        <div>{tvlNode}</div>
        <span className="label">Total Depositors</span>
        <div>{totalDepositorsNode}</div>
        <span className="label">Vault APR</span>
        <div>{aprNode}</div>
        <span className="label">Fees</span>
        <div>{feesNode}</div>
      </div>
      <div className="a-holdings">
        <Holdings
          holdingsSummary={holdingsSummary}
          pointsSummary={pointsSummary}
          vaultToken={vault.vaultToken}
        />
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  white-space: nowrap;

  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 24px;

  &.no-account {
    .a-holdings {
      img,
      .value {
        opacity: 0.5;
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: ${ROW_HEIGHT};
    gap: 24px;
    align-items: center;

    span.label {
      ${labelStyle}
    }
    span.value {
      ${valueStyle}
    }

    .two-rows {
      align-self: start;
      grid-row: span 2;
    }

    .three-rows {
      align-self: start;
      grid-row: span 3;
    }

    .col {
      display: flex;
      flex-flow: column nowrap;
      gap: 12px;
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 6px;
    }

    img.round {
      border-radius: 50%;
    }
    img.grey {
      filter: grayscale(100%);
    }
  }

  [aria-busy='true'] {
    max-width: 70px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-flow: column nowrap;
    white-space: unset;

    span.label {
      width: 175px;
    }
  }
`

function Holdings({
  holdingsSummary,
  pointsSummary,
  vaultToken,
}: {
  holdingsSummary: YearnHoldingsSummary
  pointsSummary: YearnPointsSummary
  vaultToken: Token
}) {
  return (
    <>
      <span className="label">Your {vaultToken.symbol}</span>
      <div className="row">
        <img
          className="round"
          src={vaultToken.logoURI}
          width={ICON_SIZE_PX}
          height={ICON_SIZE_PX}
        />
        <AsyncDiv loading={!holdingsSummary.vaultToken}>
          {() => <span className="value">{holdingsSummary.vaultToken}</span>}
        </AsyncDiv>
      </div>
      <span className="label">Vault Points</span>
      <div className="col">
        <span className="value row">
          <img
            className="round"
            src={URI_SYMBIOTIC_LOGO}
            width={ICON_SIZE_PX}
            height={ICON_SIZE_PX}
          />{' '}
          <AsyncDiv loading={!pointsSummary.vaultSymbioticPoints}>
            {() => pointsSummary.vaultSymbioticPoints}
          </AsyncDiv>
        </span>
      </div>
    </>
  )
}
