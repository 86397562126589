import { BigNumber } from 'ethers'
import {
  AirdropResult,
  PersistedUserClaimData,
  PersistedUserClaimDataRaw,
  PersistedUserClaimResult,
  RswellAirdropResult,
} from './types'
import { StaticAirdropResult } from '@/services/StaticAirdrop/types'
import { ClaimedEvent } from '@/submodules/v3-shared/ts/connect/swell/v3/wallet_pb'
import { MerkleClaimedEvent } from '@/types/merkle'

export function parseClaimedEvents(events: ClaimedEvent[]) {
  events = events.sort(
    (a, b) => Number(a.timestampUnix) - Number(b.timestampUnix)
  )
  const claimedEvents: MerkleClaimedEvent[] = []

  let cumulativeClaimed = BigNumber.from(0)
  for (const event of events) {
    const amountClaimed = BigNumber.from(event.amountClaimedWei)
    const amountLocked = BigNumber.from(event.amountLockedWei)
    cumulativeClaimed = cumulativeClaimed.add(amountClaimed)
    claimedEvents.push({
      amountLocked,
      cumulativeAmount: cumulativeClaimed,
      timestampUnix: Number(event.timestampUnix),
    })
  }

  return claimedEvents
}

export function serializeAirdropClaim({
  amountToLock,
  airdropResult,
  nowMs,
}: {
  amountToLock: BigNumber
  airdropResult: AirdropResult
  nowMs: number
}): PersistedUserClaimDataRaw {
  if (!airdropResult.data) {
    return {
      airdropResult: {
        data: {
          cumulativeAmount: '',
          merkleProof: [],
          merkleRoot: '',
          totalAmount: '',
        },
        loyaltyAmount: '',
        pearls: 0,
        vestingTier: 0,
        selectedOption2: false,
      },
      amountToLock: '0',
      timestampMs: 0,
    }
  }

  return {
    timestampMs: nowMs,
    amountToLock: amountToLock.toString(),
    airdropResult: {
      data: {
        cumulativeAmount: airdropResult.data.cumulativeAmount.toString(),
        merkleProof: airdropResult.data.merkleProof,
        merkleRoot: airdropResult.data.merkleRoot,
        totalAmount: airdropResult.data.totalAmount.toString(),
      },
      loyaltyAmount: airdropResult.loyaltyAmount.toString(),
      pearls: airdropResult.pearls,
      vestingTier: airdropResult.vestingTier,
      selectedOption2: airdropResult.selectedOption2,
    },
  }
}

export function deserializeAirdropClaim(
  data: PersistedUserClaimDataRaw
): PersistedUserClaimData {
  let amountToLock: BigNumber | undefined
  let cumulativeAmount: BigNumber | undefined
  let totalAmount: BigNumber | undefined
  let loyaltyAmount: BigNumber | undefined
  try {
    amountToLock = BigNumber.from(data.amountToLock)
    cumulativeAmount = BigNumber.from(data.airdropResult.data.cumulativeAmount)
    totalAmount = BigNumber.from(data.airdropResult.data.totalAmount)
    loyaltyAmount = BigNumber.from(data.airdropResult.loyaltyAmount)
  } catch (e) {
    console.error('parse airdrop claim data', e)
    return {
      timestampMs: 0,
      amountToLock: BigNumber.from(0),
      airdropResult: {
        data: {
          cumulativeAmount: BigNumber.from(0),
          merkleProof: [],
          merkleRoot: '',
          totalAmount: BigNumber.from(0),
        },
        loyaltyAmount: BigNumber.from(0),
        pearls: 0,
        vestingTier: 0,
        selectedOption2: false,
      },
    }
  }

  return {
    timestampMs: data.timestampMs,
    amountToLock,
    airdropResult: {
      data: {
        cumulativeAmount,
        merkleProof: data.airdropResult.data.merkleProof,
        merkleRoot: data.airdropResult.data.merkleRoot,
        totalAmount,
      },
      loyaltyAmount,
      pearls: data.airdropResult.pearls,
      vestingTier: data.airdropResult.vestingTier,
      selectedOption2: data.airdropResult.selectedOption2,
    },
  }
}
