import {
  useSwETHCalls,
  useSwETHStakingPoolActivityCount,
  useSwETHStakingPoolActivityPage,
  useSwETHState,
  useSwETHStats,
  useSwETHTokens,
  useSwETHUser,
  useSwETHWithdrawalsUser,
} from '@/state/sweth/hooks'
import {
  useStakingPoolActivityInputs,
  useSwETHClaimInputs,
  useSwETHDepositInputs,
  useSwETHPageLiveness,
  useSwETHResetInputs,
  useSwETHWithdrawInputs,
} from './stakeSwETHHooks'
import { useEthBalance } from '@/state/user/hooks'
import {
  makeSwETHClaimSummary,
  makeSwETHDepositSummary,
  makeSwETHStatsSummary,
  makeSwETHWithdrawSummary,
} from './swETHFormatting'
import { usePrices, usePrimaryRates } from '@/state/prices/hooks'
import useTransactionFee from '@/hooks/useTransactionFee'
import {
  getStakingUpperGasEstimate,
  getSwETHFinalizeWithdrawalGasEstimate,
} from '@/constants/gasEstimates'
import {
  prepareApproveSwETHForWithdrawal,
  prepareSwETHCreateWithdrawRequest,
  prepareSwETHDeposit,
  prepareSwETHFinalizeWithdrawal,
} from './swETHCalls'
import { parseEther } from 'ethers/lib/utils'
import { SwETHWidget } from './SwETHWidget'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import {
  useWeb3CallAutoClearError,
  useWeb3CallClearOnFulfill,
} from '@/hooks/useWeb3Events'
import { TransactionContext } from '@/state/transactions/context'

const MAX_UNSTAKE_AMOUNT = parseEther('500')
const MIN_UNSTAKE_AMOUNT = parseEther('0.005')

export function StakeSwETH() {
  const { account } = useSwellWeb3()

  const calls = useSwETHCalls()
  for (const call of Object.values(calls)) {
    useWeb3CallErrorNotify(call)
    useWeb3CallClearOnFulfill(call)
    useWeb3CallAutoClearError(call)
  }
  const anyTransactionInProgress = Object.values(calls).some(
    (x) => x.status === x.STATUS.PENDING || x.status === x.STATUS.PROMPTING
  )

  const stakingActivityInputs = useStakingPoolActivityInputs()

  const { nativeCurrency, swETHToken, exitSwETHETH } = useSwETHTokens()
  const stateQuery = useSwETHState()
  const userQuery = useSwETHUser()
  const statsQuery = useSwETHStats()
  const ethBalanceQuery = useEthBalance()
  const withdrawUserQuery = useSwETHWithdrawalsUser()
  const primaryRatesQuery = usePrimaryRates()
  const pricesQuery = usePrices()
  const activityCountQuery = useSwETHStakingPoolActivityCount()
  const activityItemsQuery = useSwETHStakingPoolActivityPage({
    page: stakingActivityInputs.selectedPage,
  })

  const state = stateQuery.data
  const user = userQuery.data
  const stats = statsQuery.data
  const ethBalance = ethBalanceQuery.data?.balance
  const withdrawUser = withdrawUserQuery.data
  const primaryRates = primaryRatesQuery.data
  const prices = pricesQuery.data
  const activityCount = activityCountQuery.data
  const activityItems = activityItemsQuery.data

  const mutateSwethState = stateQuery.mutate
  const mutateSwethUser = userQuery.mutate
  const mutateSwethStats = statsQuery.mutate
  const mutateEthBalance = ethBalanceQuery.mutate
  const mutateWithdrawUser = withdrawUserQuery.mutate
  const mutatePrimaryRates = primaryRatesQuery.mutate
  const mutatePrices = pricesQuery.mutate
  const mutateActivity = activityItemsQuery.mutate

  useSwETHPageLiveness({
    calls,
    mutateEthBalance,
    mutateSwethState,
    mutateSwethStats,
    mutateSwethUser,
    mutateWithdrawUser,
    mutatePrimaryRates,
    mutatePrices,
    mutateActivity,
    globalDataRefreshInterval: 20_000,
  })

  const depositFees = useTransactionFee({
    gasAmount: getStakingUpperGasEstimate().toNumber(),
  })
  const withdrawFees = useTransactionFee({
    gasAmount: getSwETHFinalizeWithdrawalGasEstimate().toNumber(),
  })
  const claimSummary = makeSwETHClaimSummary({
    withdrawUser,
    chainId: exitSwETHETH.chainId,
    swEXITAddress: exitSwETHETH.exitAddress,
  })
  const depositInputs = useSwETHDepositInputs({
    nativeCurrency,
    swETHToken,
    ethBalance,
    primaryRates,
  })
  const withdrawInputs = useSwETHWithdrawInputs({
    nativeCurrency,
    swETHToken,
    primaryRates,
    user,
    maxUnstakeAmount: MAX_UNSTAKE_AMOUNT,
    minUnstakeAmount: MIN_UNSTAKE_AMOUNT,
  })
  const claimInputs = useSwETHClaimInputs({
    claimSummary,
  })
  useSwETHResetInputs({ account, calls, depositInputs, withdrawInputs })

  const statsSummary = makeSwETHStatsSummary({
    primaryRates,
    state,
    stats,
    nativeCurrency,
  })
  const depositSummary = makeSwETHDepositSummary({
    account,
    ethBalance,
    fees: depositFees.data,
    inputs: depositInputs,
    nativeCurrency,
    prices,
    primaryRates,
    swETHToken,
  })
  const withdrawSummary = makeSwETHWithdrawSummary({
    account,
    fees: withdrawFees.data,
    inputs: withdrawInputs,
    nativeCurrency,
    prices,
    primaryRates,
    swETHToken,
    user,
  })
  const preparedDeposit = prepareSwETHDeposit({
    depositInputs,
    ethBalance,
    state,
    user,
  })
  const preparedApproveSwETHForWithdrawal = prepareApproveSwETHForWithdrawal({
    user,
    withdrawInputs,
  })
  const preparedCreateWithdrawRequest = prepareSwETHCreateWithdrawRequest({
    maxUnstakeAmount: MAX_UNSTAKE_AMOUNT,
    minUnstakeAmount: MIN_UNSTAKE_AMOUNT,
    user,
    withdrawInputs,
    state,
  })
  const preparedFinalizeWithdrawRequest = prepareSwETHFinalizeWithdrawal({
    withdrawUser,
    claimInputs,
  })

  return (
    <TransactionContext.Provider value={{ anyTransactionInProgress }}>
      <SwETHWidget
        primaryRates={primaryRates}
        withdrawUser={withdrawUser}
        claimSummary={claimSummary}
        statsSummary={statsSummary}
        depositSummary={depositSummary}
        withdrawSummary={withdrawSummary}
        depositInputs={depositInputs}
        withdrawInputs={withdrawInputs}
        claimInputs={claimInputs}
        calls={calls}
        preparedDeposit={preparedDeposit}
        preparedApproveSwETHForWithdrawal={preparedApproveSwETHForWithdrawal}
        preparedCreateWithdrawRequest={preparedCreateWithdrawRequest}
        activityCounts={activityCount}
        activityItems={activityItems}
        activityInputs={stakingActivityInputs}
        nativeCurrency={nativeCurrency}
        swETHToken={swETHToken}
        preparedFinalizeWithdrawRequest={preparedFinalizeWithdrawRequest}
      />
    </TransactionContext.Provider>
  )
}
