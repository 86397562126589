import { CumulativeMerkleDrop__factory, Multicall3 } from '@/abis/types'
import { BigNumber, ethers } from 'ethers'
import {
  AirdropResult,
  DaoMerkleContractsState,
  RswellAirdropResult,
} from './types'
import { MerkleDrop } from '@/types/merkle'
import { parseClaimedEvents } from './util'
import { WalletClient } from '@/services/V3BackendService/hooks'
import { prefix0x } from '@/util/hexStrings'
import { IStaticAirdropServiceV2 } from '@/services/StaticAirdropV2/types'

export async function fetchAirdropUser({
  account,
  merkleDropAirdrop,
  walletClient,
  staticAirdropService,
  multicall,
  merkleDropRswell,
}: {
  merkleDropAirdrop: MerkleDrop
  account: string
  walletClient: WalletClient
  staticAirdropService: IStaticAirdropServiceV2
  multicall: Multicall3
  merkleDropRswell: MerkleDrop
}): Promise<AirdropResult> {
  const airdropDataP = staticAirdropService.airdrop(account)
  const eventsSwellP = walletClient.claimedEvents({
    merkledropAddress: merkleDropAirdrop.address,
    walletAddress: account,
  })
  const eventsRswellP = walletClient.claimedEvents({
    merkledropAddress: merkleDropRswell.address,
    walletAddress: account,
  })

  const calls: Multicall3.Call3Struct[] = []
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'cumulativeClaimed',
        [account]
      ),
  })
  calls.push({
    target: merkleDropRswell.address,
    allowFailure: true,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropRswell.address,
    allowFailure: true,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'cumulativeClaimed',
        [account]
      ),
  })

  const [
    merkleRootResult,
    cumulativeClaimedResult,
    merkleRootRswellResult,
    cumulativeClaimedRswellResult,
  ] = await multicall.callStatic.tryAggregate(true, calls)

  const currentMerkleRoot =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'merkleRoot',
      merkleRootResult.returnData
    )[0]
  const cumulativeClaimed =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'cumulativeClaimed',
      cumulativeClaimedResult.returnData
    )[0]
  const currentMerkleRootRswell =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'merkleRoot',
      merkleRootRswellResult.returnData
    )[0]
  const cumulativeClaimedRswell =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'cumulativeClaimed',
      cumulativeClaimedRswellResult.returnData
    )[0]

  const airdropData = await airdropDataP
  if (airdropData.data) {
    if (prefix0x(airdropData.data.merkleRoot) !== currentMerkleRoot) {
      // throw new Error('Merkle root mismatch (swell)')
    }

    if (airdropData.data.rswell.exists) {
      if (
        prefix0x(airdropData.data.rswell.data.merkleRoot) !==
        currentMerkleRootRswell
      ) {
        // throw new Error('Merkle root mismatch (rswell)')
      }
    }
  }

  const { isSybil, pearls } = airdropData

  if (!airdropData.data) {
    return {
      exists: false,
      pearls,
      isSybil,
    }
  }

  const {
    cumulativeAmount,
    loyaltyAmount,
    merkleRoot,
    proofsHex,
    selectedOption2,
    totalAmount,
    vestingTier,
  } = airdropData.data

  const { events: swellEvents } = await eventsSwellP
  const { events: rswellEvents } = await eventsRswellP
  const claimedSwellEvents = parseClaimedEvents(swellEvents)
  const claimedRswellEvents = parseClaimedEvents(rswellEvents)

  let rswellAirdrop: RswellAirdropResult
  if (airdropData.data.rswell.exists) {
    rswellAirdrop = {
      exists: true,
      claimedEvents: claimedRswellEvents,
      cumulativeClaimed: cumulativeClaimedRswell,
      data: {
        address: account,
        cumulativeAmount: airdropData.data.rswell.data.cumulativeAmount,
        merkleProof: airdropData.data.rswell.data.proofsHex,
        merkleRoot: airdropData.data.rswell.data.merkleRoot,
        totalAmount: airdropData.data.rswell.data.cumulativeAmount,
      },
    }
  } else {
    rswellAirdrop = { exists: false }
  }

  return {
    exists: true,
    rswellAirdrop,
    data: {
      address: account,
      cumulativeAmount: BigNumber.from(cumulativeAmount),
      merkleRoot: merkleRoot,
      merkleProof: proofsHex,
      totalAmount: BigNumber.from(totalAmount),
    },
    pearls,
    cumulativeClaimed,
    loyaltyAmount,
    isSybil: airdropData.isSybil,
    claimedEvents: claimedSwellEvents,
    vestingTier,
    selectedOption2,
  }
}

export async function fetchAirdropContractsState({
  merkleDropAirdropAddress,
  merkleDropRswellAddress,
  multicall,
}: {
  merkleDropAirdropAddress: string
  merkleDropRswellAddress: string
  multicall: Multicall3
}): Promise<DaoMerkleContractsState> {
  const calls: Multicall3.Call3Struct[] = []
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'claimIsOpen'
      ),
  })
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'stakingContract'
      ),
  })
  calls.push({
    target: merkleDropRswellAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropRswellAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'claimIsOpen'
      ),
  })

  const [
    merkleRootAirdropResult,
    claimIsOpenAirdropResult,
    stakingContract,
    merkleRootRswellResult,
    claimIsOpenRswellResult,
  ] = await multicall.callStatic.tryAggregate(true, calls)

  const merkleRootAirdrop =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'merkleRoot',
      merkleRootAirdropResult.returnData
    )[0]
  const claimIsOpenAirdrop =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'claimIsOpen',
      claimIsOpenAirdropResult.returnData
    )[0]
  const stakingContractAddress =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'stakingContract',
      stakingContract.returnData
    )[0]
  const merkleRootRswell =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'merkleRoot',
      merkleRootRswellResult.returnData
    )[0]
  const claimIsOpenRswell =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'claimIsOpen',
      claimIsOpenRswellResult.returnData
    )[0]

  const stakingExists = stakingContractAddress !== ethers.constants.AddressZero

  return {
    swell: {
      merkleDrop: {
        claimIsOpen: claimIsOpenAirdrop,
        merkleRoot: merkleRootAirdrop,
      },
      staking: stakingExists
        ? { exists: true, kind: 'vault', isPaused: false }
        : { exists: false },
    },
    rswell: {
      merkleDrop: {
        claimIsOpen: claimIsOpenRswell,
        merkleRoot: merkleRootRswell,
      },
      staking: { exists: false },
    },
  }
}
