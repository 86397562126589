import { useBtcUsdMarketRate } from '@/state/prices/hooks'
import {
  useNucleusBtcAllowances,
  useNucleusBtcAuth,
  useNucleusBtcBalances,
  useNucleusBtcPoints,
  useNucleusBtcRates,
  useNucleusBtcRecentFulfilledRequests,
  useNucleusBtcSharesState,
  useNucleusBtcSupportedAssets,
  useNucleusBtcVault,
  useNucleusBtcVaultCalls,
  useNucleusBtcVaultState,
  useNucleusBtcVaultStats,
  useNucleusBtcWithdrawRequest,
} from '@/state/nucleusVault/hooks'
import nucleusLogoUrl from '@/assets/images/nucleus_logo_64x64.png'
import {
  useEarnVaultClearTxOnSuccess,
  useEarnVaultRefetchOnAccountChange,
  useEarnVaultRefetchOnTxSuccess,
  useEarnVaultRefetchVaultStateOnError,
} from './hooks'
import { NucleusPointsCampaigns } from '@/components/Nucleus/types'
import { EarnVaultPageTemplate } from '@/components/EarnPage/EarnVaultPageTemplate'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { TransactionContext } from '@/state/transactions/context'
import btcLogo from '@/assets/images/btc_logo-160x160.png'

const VAULT_LEARN_MORE_URL =
  'https://docs.swellnetwork.io/swell-earn/earnbtc-vault'

const POINTS_CAMPAIGNS: NucleusPointsCampaigns = {
  nucleusPtsCampaign: {
    logoURI: nucleusLogoUrl,
    multiplier: 3,
    name: 'Nucleus Pts',
    comingSoon: true,
  },
  btcPartnerPtsCampaign: {
    logoURI: btcLogo,
    multiplier: 0,
    name: 'BTC Partner Pts',
    comingSoon: true,
  },
}

const ZEROX_MACRO_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/0xmacro-boring-vault-arctic-0.pdf'
const PASHOV_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/pashov-boring-vault.pdf'
const SPEARBIT_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/spearbit-boring-vault-arctic-0.pdf'

const DESCRIPTION = (
  <>
    <h3>Vault description</h3>
    <p>
      earnBTC is a BTC denominated vault that allows users to deposit their BTC
      assets to get automated risk adjusted yields and points.{' '}
      <a href={VAULT_LEARN_MORE_URL} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    </p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Deposit Assets</span>
    </p>
    <p>wBTC, swBTC, cbBTC, fBTC, tBTC, stBTC, UBTC, and LBTC</p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Audited by</span>
    </p>
    <p>
      <a href={ZEROX_MACRO_URL} target="_blank" rel="noopener noreferrer">
        0xMacro
      </a>
      ,{' '}
      <a href={PASHOV_URL} target="_blank" rel="noopener noreferrer">
        Pashov
      </a>
      ,{' '}
      <a href={SPEARBIT_URL} target="_blank" rel="noopener noreferrer">
        Spearbit
      </a>
    </p>
  </>
)

// TODO: real copy
const PERFORMANCE = (
  <>
    <h3>Performance over time</h3>
    <p>
      Deposited assets are deployed into a basket of DeFi protocols, earning a
      variable yield based on market conditions. Periodically, the vault will
      rebalance allocations across DeFi positions to maximize returns while
      staying within risk policy. Any rewards earned in positions are sold for
      more weETH to redistribute across strategies.
    </p>
    <p>Chart denotes performance of vault gains since inception</p>
  </>
)

export function EarnBtcPage() {
  const calls = useNucleusBtcVaultCalls()
  const vault = useNucleusBtcVault()

  const anyTransactionInProgress = [...Object.values(calls)].some(
    (call) =>
      call.status === call.STATUS.PENDING ||
      call.status === call.STATUS.PROMPTING
  )

  for (const call of Object.values(calls)) {
    useWeb3CallErrorNotify(call)
  }
  useEarnVaultClearTxOnSuccess({ calls })

  // user
  const allowancesQuery = useNucleusBtcAllowances()
  const authQuery = useNucleusBtcAuth()
  const balancesQuery = useNucleusBtcBalances()
  const sharesStateQuery = useNucleusBtcSharesState()
  const currentWithdrawRequestQuery = useNucleusBtcWithdrawRequest()
  const fulfilledRequestsQuery = useNucleusBtcRecentFulfilledRequests()
  const pointsQuery = useNucleusBtcPoints()

  // vault
  const statsQuery = useNucleusBtcVaultStats()
  const ratesQuery = useNucleusBtcRates()
  const supportedTokensQuery = useNucleusBtcSupportedAssets()
  const vaultStateQuery = useNucleusBtcVaultState()

  // rates
  const wbtcRateUsdQuery = useBtcUsdMarketRate()

  const refetchAllowances = allowancesQuery.mutate
  const refetchBalances = balancesQuery.mutate
  const refetchCurrentWithdrawRequest = currentWithdrawRequestQuery.mutate

  useEarnVaultRefetchOnAccountChange({
    refetchAllowances,
    refetchBalances,
    refetchCurrentWithdrawRequest,
  })
  useEarnVaultRefetchOnTxSuccess({
    calls,
    refetchAllowances,
    refetchBalances,
    refetchCurrentWithdrawRequest,
  })

  const stats = statsQuery.data
  const allowances = allowancesQuery.data
  const auth = authQuery.data
  const balances = balancesQuery.data
  const rates = ratesQuery.data
  const sharesState = sharesStateQuery.data
  const supportedTokens = supportedTokensQuery.data
  const vaultState = vaultStateQuery.data
  const currentWithdrawRequest = currentWithdrawRequestQuery.data
  const fulfilledRequests = fulfilledRequestsQuery.data

  const points = pointsQuery.data

  useEarnVaultRefetchVaultStateOnError({
    calls: calls,
    refetchVaultState: vaultStateQuery.mutate,
  })

  return (
    <TransactionContext.Provider value={{ anyTransactionInProgress }}>
      <EarnVaultPageTemplate
        pageDescription={
          'A vault aggregator that earns rewards on your Bitcoin assets in DeFi.'
        }
        title={'Swell Earn BTC Vault'}
        noPerformanceData
        vault={vault}
        baseAssetRateUsd={wbtcRateUsdQuery.data?.rate}
        calls={calls}
        stats={stats}
        allowances={allowances}
        auth={auth}
        balances={balances}
        rates={rates}
        sharesState={sharesState}
        supportedTokens={supportedTokens}
        vaultState={vaultState}
        currentWithdrawRequest={currentWithdrawRequest}
        points={points}
        descriptionNode={DESCRIPTION}
        performanceNode={PERFORMANCE}
        fulfilledRequests={fulfilledRequests}
        pointsCampaigns={POINTS_CAMPAIGNS}
      />
    </TransactionContext.Provider>
  )
}
