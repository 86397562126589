import whitePearlUrl from '@/assets/svg/voyage/pearl.svg'
import blackPearlLogo from '@/assets/images/black-pearl-80x80.png'
import swellL2PointsLogo from '@/assets/images/swell-l2-logo-56x56.png'
import ionLogo from '@/assets/images/predeposit/ion.png'
import eigenLogo from '@/assets/images/eigen-80x80.png'
import neptuneLogo from '@/assets/images/neptune_finance_160x160.png'
import ambientLogo from '@/assets/images/predeposit/ambient.png'
import eulerLogo from '@/assets/images/euler_160x160.png'
import tempestLogo from '@/assets/images/tempest_logo_120x120.png'
import dittoLogo from '@/assets/images/ditto-logo.png'
import nucleusLogo from '@/assets/images/nucleus_logo_64x64.png'

const symbioticLogo = 'https://static-staging.svc.swellnetwork.io/symbiotic.svg'
const chainsecurityLogo =
  'https://static-staging.svc.swellnetwork.io/Chainsecurity.svg'
const nethermindLogo =
  'https://static-staging.svc.swellnetwork.io/Nethermind.svg'
const xveloLogo = 'https://static-staging.svc.swellnetwork.io/xVELO.svg'
const velodromeWhiteLogo = 'https://static-staging.svc.swellnetwork.io/Velodrome_white.png'
const inkLogo = 'https://static-staging.svc.swellnetwork.io/ink.webp'
const wbtcLogo = 'https://static-staging.svc.swellnetwork.io/wBTC.svg'
const rETHLogo = 'https://static-staging.svc.swellnetwork.io/rETH.svg'
const eETHLogo = 'https://static-staging.svc.swellnetwork.io/etherfi_eETH.webp'
const weETHLogo =
  'https://static-staging.svc.swellnetwork.io/etherfi_weETH.webp'
const cbETHLogo = 'https://static-staging.svc.swellnetwork.io/cbETH.svg'
const wbETHLogo = 'https://static-staging.svc.swellnetwork.io/wbETH.svg'
const methLogo = 'https://static-staging.svc.swellnetwork.io/mETH.svg'
const srfxLogo = 'https://static-staging.svc.swellnetwork.io/srfxETH.svg'
const ethxLogo = 'https://static-staging.svc.swellnetwork.io/ETHx.svg'
const usdLogo =
  'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389'
const wstethLogo = 'https://static-staging.svc.swellnetwork.io/wstETH.svg'
const kalypsoLogo = `https://static-staging.svc.swellnetwork.io/kalyspo_160x160.png`
const radiusLogo = `https://static-staging.svc.swellnetwork.io/radius_160x160.png`
const primevLogo = `https://static-staging.svc.swellnetwork.io/primev_white_160x160.png`

export const URI_WHITE_PEARL = whitePearlUrl
export const URI_SYMBIOTIC_LOGO = symbioticLogo
export const URI_CHAINSECURITY_LOGO = chainsecurityLogo
export const URI_NETHERMIND_LOGO = nethermindLogo
export const URI_BLACK_PEARL = blackPearlLogo
export const URI_SWELL_L2_POINTS_LOGO = swellL2PointsLogo
export const URI_ION_LOGO = ionLogo
export const URI_EIGEN_LOGO = eigenLogo
export const URI_NEPTUNE_LOGO = neptuneLogo
export const URI_AMBIENT_LOGO = ambientLogo
export const URI_EULER_LOGO = eulerLogo
export const URI_TEMPEST_LOGO = tempestLogo
export const URI_WBTC_LOGO = wbtcLogo
export const URI_RETH_LOGO = rETHLogo
export const URI_EETH_LOGO = eETHLogo
export const URI_WEETH_LOGO = weETHLogo
export const URI_CBETH_LOGO = cbETHLogo
export const URI_WBETH_LOGO = wbETHLogo
export const URI_METH_LOGO = methLogo
export const URI_SFRX_LOGO = srfxLogo
export const URI_ETHX_LOGO = ethxLogo
export const URI_USD_LOGO = usdLogo
export const URI_WSTETH_LOGO = wstethLogo
export const URI_KALYPSO_LOGO = kalypsoLogo
export const URI_DITTO_LOGO = dittoLogo
export const URI_RADIUS_LOGO = radiusLogo
export const URI_PRIMEV_LOGO = primevLogo
export const URI_NUCLEUS_LOGO = nucleusLogo
export const URI_XVELO_LOGO = xveloLogo
export const URI_VELODROME_WHITE_LOGO = velodromeWhiteLogo
export const URI_INK_LOGO = inkLogo
