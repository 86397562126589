import { useDeploymentSetConfig } from './useDeploymentSetConfig'

export function useWavedropsSettings() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()

  return {
    preClaimBackendURL: v3BackendLrtUrl,
    wavedropServiceURL: 'https://airdrop.svc.swellnetwork.io',
    // todo: claim backend
  }
}
