import {
  useNucleusEthAllowances,
  useNucleusEthAuth,
  useNucleusEthBalances,
  useNucleusEthPoints,
  useNucleusEthRates,
  useNucleusEthRecentFulfilledRequests,
  useNucleusEthSharesState,
  useNucleusEthSupportedAssets,
  useNucleusEthVault,
  useNucleusEthVaultCalls,
  useNucleusEthVaultState,
  useNucleusEthVaultStats,
  useNucleusEthWithdrawRequest,
} from '@/state/nucleusVault/hooks'
import nucleusLogoUrl from '@/assets/images/nucleus_logo_64x64.png'
import { useEthUsdRate } from '@/state/prices/hooks'
import {
  useEarnVaultRefetchOnAccountChange,
  useEarnVaultClearTxOnSuccess,
  useEarnVaultRefetchOnTxSuccess,
  useEarnVaultRefetchVaultStateOnError,
} from './hooks'
import { NucleusPointsCampaigns } from '@/components/Nucleus/types'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { TransactionContext } from '@/state/transactions/context'
import { EarnEthVaultPageTemplate } from '@/components/EarnPage/EarnEthVaultPageTemplate'
import { usePredepositCalls, usePredepositUser } from '@/state/predeposit/hooks'

const POINTS_CAMPAIGNS: NucleusPointsCampaigns = {
  nucleusPtsCampaign: {
    logoURI: nucleusLogoUrl,
    multiplier: 3,
    name: 'Nucleus Pts',
    comingSoon: true,
  },
}

const ZEROX_MACRO_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/0xmacro-boring-vault-arctic-0.pdf'
const PASHOV_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/pashov-boring-vault.pdf'
const SPEARBIT_URL =
  'https://github.com/Ion-Protocol/nucleus-boring-vault/blob/master/audit/spearbit-boring-vault-arctic-0.pdf'

const VAULT_LEARN_MORE_URL =
  'https://docs.swellnetwork.io/swell-earn/earneth-vault'

const DESCRIPTION = (
  <>
    <h3>Vault description</h3>
    <p>
      earnETH is an ETH denominated vault that allows users to deposit their ETH
      assets to get automated risk adjusted yields and points.{' '}
      <a href={VAULT_LEARN_MORE_URL} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    </p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Deposit Assets</span>
    </p>
    <p>WETH, rswETH, swETH, WSTETH, pxETH, apxETH, weETH, ezETH</p>
    <p>
      <span style={{ fontWeight: 'bold' }}>Audited by</span>
    </p>
    <p>
      <a href={ZEROX_MACRO_URL} target="_blank" rel="noopener noreferrer">
        0xMacro
      </a>
      ,{' '}
      <a href={PASHOV_URL} target="_blank" rel="noopener noreferrer">
        Pashov
      </a>
      ,{' '}
      <a href={SPEARBIT_URL} target="_blank" rel="noopener noreferrer">
        Spearbit
      </a>
    </p>
  </>
)

// TODO: real copy
const PERFORMANCE = (
  <>
    <h3>TODO TODO TODO</h3>
    <p>
      Deposited assets are deployed into a basket of DeFi protocols, earning a
      variable yield based on market conditions. Periodically, the vault will
      rebalance allocations across DeFi positions to maximize returns while
      staying within risk policy. Any rewards earned in positions are sold for
      more weETH to redistribute across strategies.
    </p>
    <p>Chart denotes performance of vault gains since inception</p>
  </>
)

export function EarnEthPage() {
  const calls = useNucleusEthVaultCalls()
  const vault = useNucleusEthVault()
  const predepositCalls = usePredepositCalls()

  const ALL_CALLS = [...Object.values(calls), ...Object.values(predepositCalls)]
  const anyTransactionInProgress = ALL_CALLS.some(
    (call) =>
      call.status === call.STATUS.PENDING ||
      call.status === call.STATUS.PROMPTING
  )

  for (const call of ALL_CALLS) {
    useWeb3CallErrorNotify(call)
  }
  useEarnVaultClearTxOnSuccess({ calls })

  // user
  const allowancesQuery = useNucleusEthAllowances()
  const authQuery = useNucleusEthAuth()
  const balancesQuery = useNucleusEthBalances()
  const sharesStateQuery = useNucleusEthSharesState()
  const currentWithdrawRequestQuery = useNucleusEthWithdrawRequest()
  const fulfilledRequestsQuery = useNucleusEthRecentFulfilledRequests()
  const pointsQuery = useNucleusEthPoints()
  const predepositUserQuery = usePredepositUser()

  // vault
  const statsQuery = useNucleusEthVaultStats()
  const ratesQuery = useNucleusEthRates()
  const supportedTokensQuery = useNucleusEthSupportedAssets()
  const vaultStateQuery = useNucleusEthVaultState()

  const ethRateUsdQuery = useEthUsdRate()

  const refetchAllowances = allowancesQuery.mutate
  const refetchBalances = balancesQuery.mutate
  const refetchCurrentWithdrawRequest = currentWithdrawRequestQuery.mutate

  useEarnVaultRefetchOnAccountChange({
    refetchAllowances,
    refetchBalances,
    refetchCurrentWithdrawRequest,
  })
  useEarnVaultRefetchOnTxSuccess({
    calls,
    refetchAllowances,
    refetchBalances,
    refetchCurrentWithdrawRequest,
  })

  const stats = statsQuery.data
  const allowances = allowancesQuery.data
  const auth = authQuery.data
  const balances = balancesQuery.data
  const rates = ratesQuery.data
  const sharesState = sharesStateQuery.data
  const supportedTokens = supportedTokensQuery.data
  const vaultState = vaultStateQuery.data
  const currentWithdrawRequest = currentWithdrawRequestQuery.data
  const fulfilledRequests = fulfilledRequestsQuery.data

  const points = pointsQuery.data
  const ethUsdRate = ethRateUsdQuery.data?.rate
  const predepositUser = predepositUserQuery.data

  useEarnVaultRefetchVaultStateOnError({
    calls,
    refetchVaultState: vaultStateQuery.mutate,
  })

  return (
    <TransactionContext.Provider value={{ anyTransactionInProgress }}>
      <EarnEthVaultPageTemplate
        pageDescription={
          'A vault aggregator that earns yield on your Ethereum assets in DeFi.'
        }
        title={'Swell Earn ETH Vault'}
        noPerformanceData
        calls={calls}
        withdrawFromPredeposit={predepositCalls.withdrawFromPredeposit}
        stats={stats}
        allowances={allowances}
        auth={auth}
        balances={balances}
        rates={rates}
        sharesState={sharesState}
        supportedTokens={supportedTokens}
        vaultState={vaultState}
        currentWithdrawRequest={currentWithdrawRequest}
        points={points}
        descriptionNode={DESCRIPTION}
        performanceNode={PERFORMANCE}
        fulfilledRequests={fulfilledRequests}
        vault={vault}
        nucleusPoints={POINTS_CAMPAIGNS.nucleusPtsCampaign}
        baseAssetRateUsd={ethUsdRate}
        predepositUser={predepositUser}
      />
    </TransactionContext.Provider>
  )
}
