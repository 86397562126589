import ms from 'ms.macro'
import { ChainId, SupportedChainId } from './chains'
import arbitrumLogoUrl from '@/assets/images/chains/arbitrum-64x64.png'
import basechainLogoUrl from '@/assets/images/chains/base_160x160.png'
import blastLogoUrl from '@/assets/images/chains/blast-64x64.png'
import optimismLogoUrl from '@/assets/images/chains/optimism-64x64.png'
import starknetLogoUrl from '@/assets/images/chains/starknet-64x64.png'
import zksyncLogoUrl from '@/assets/images/chains/zksync-64x64.png'
import berachainLogoUrl from '@/assets/images/chains/berachain-64x64.png'
import monadLogoUrl from '@/assets/images/chains/monad-64x64.png'
import zircuitLogoUrl from '@/assets/images/chains/zircuit-64x64.png'
import fraxtalLogoUrl from '@/assets/images/chains/fraxtal-64x64.png'
import corechainLogoUrl from '@/assets/images/chains/corechain-64x64.png'
import swellL2Large from '@/assets/images/swell_l2_large.png'
import ethereumLogo from '@/assets/images/chains/eth_160x160.png'
import liskLogo from '@/assets/svg/chain/Lisk.svg'
import mantleLogo from '@/assets/svg/chain/Mantle.svg'
import modeLogo from '@/assets/svg/chain/Mode.svg'
import { URI_INK_LOGO } from './logo'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

export interface CurrencyMinimal {
  name: string // e.g. 'Goerli ETH',
  symbol: string // e.g. 'gorETH',
  decimals: number // e.g. 18,
}

export interface ChainInfo {
  readonly explorer: string
  readonly logoUrl: string
  readonly label: string
  readonly nativeCurrency: CurrencyMinimal
}

type ChainInfoMap = {
  readonly [chainId: number]: ChainInfo
}

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogo,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.SWELL]: {
    explorer: 'https://explorer.swellnetwork.io',
    label: 'Swellchain',
    logoUrl: swellL2Large,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.SWELL_TESTNET]: {
    explorer: 'https://swell-testnet-explorer.alt.technology',
    label: 'Swell Testnet',
    logoUrl: swellL2Large,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.HARDHAT]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogo,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Goerli',
    logoUrl: ethereumLogo,
    nativeCurrency: { name: 'Goerli Ether', symbol: 'goETH', decimals: 18 },
  },
  [SupportedChainId.SEPOLIA]: {
    explorer: 'https://sepolia.etherscan.io/',
    label: 'Sepolia',
    logoUrl: ethereumLogo,
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'sepETH', decimals: 18 },
  },
  [SupportedChainId.HOLESKY]: {
    explorer: 'https://holesky.etherscan.io/',
    label: 'Holesky',
    logoUrl: ethereumLogo,
    nativeCurrency: { name: 'Holesky Ether', symbol: 'holETH', decimals: 18 },
  },
  [SupportedChainId.ARBITRUM]: {
    explorer: 'https://arbiscan.io/',
    label: 'Arbitrum',
    logoUrl: arbitrumLogoUrl,
    nativeCurrency: { name: 'Arbitrum Ether', symbol: 'arbETH', decimals: 18 },
  },
  [SupportedChainId.OPTIMISM]: {
    explorer: 'https://optimistic.etherscan.io/',
    label: 'Optimism',
    logoUrl: optimismLogoUrl,
    nativeCurrency: { name: 'Optimism Ether', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.OPTIMISM_SEPOLIA]: {
    explorer: 'https://optimistic-sepolia.etherscan.io/',
    label: 'Optimism Sepolia',
    logoUrl: optimismLogoUrl,
    nativeCurrency: {
      name: 'Optimism Sepolia Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
}

export function getChainInfo(chainId: SupportedChainId): ChainInfo

export function getChainInfo(chainId: SupportedChainId): ChainInfo {
  return CHAIN_INFO[chainId]
}

export function getChainIcon(chainId: number) {
  let uri = getChainInfo(chainId)?.logoUrl
  let shape: 'circle' | 'square' = 'circle'

  if (!uri) {
    switch (chainId) {
      case ChainId.SWELLCHAIN:
        uri = swellL2Large
        break
      case ChainId.SWELL_TESTNET:
        uri = swellL2Large
        break
      case ChainId.ARBITRUM:
      case ChainId.ARBITRUM_ONE:
        uri = arbitrumLogoUrl
        break
      case ChainId.BASE:
        uri = basechainLogoUrl
        break
      case ChainId.BLAST:
        uri = blastLogoUrl
        break
      case ChainId.OPTIMISM:
        uri = optimismLogoUrl
        break
      case ChainId.STARKNET:
        uri = starknetLogoUrl
        break
      case ChainId.ZKSYNC:
        uri = zksyncLogoUrl
        break
      case ChainId.BERACHAIN:
        uri = berachainLogoUrl
        break
      case ChainId.MONAD:
        uri = monadLogoUrl
        break
      case ChainId.ZIRCUIT_TESTNET:
      case ChainId.ZIRCUIT:
        uri = zircuitLogoUrl
        break
      case ChainId.FRAXTAL:
        uri = fraxtalLogoUrl
        break
      case ChainId.CORE:
        uri = corechainLogoUrl
        break
      case ChainId.MANTLE:
      case ChainId.MANTLE_TESTNET:
        uri = mantleLogo
        break
      case ChainId.MODE:
      case ChainId.MODE_TESTNET:
        uri = modeLogo
        break
      case ChainId.LISK:
        uri = liskLogo
        break
      case ChainId.INK:
        uri = URI_INK_LOGO
        break
      default:
        uri = ethereumLogo
        break
    }
  }

  if (chainId === ChainId.SWELLCHAIN) {
    shape = 'square'
  } else if (chainId === ChainId.SWELL_TESTNET) {
    shape = 'square'
  }

  return { uri, shape }
}

export function getChainName(chainId: SupportedChainId) {
  let name = getChainInfo(chainId)?.label
  if (!name) {
    switch (chainId) {
      case ChainId.SWELLCHAIN:
        name = 'Swellchain'
        break
      case ChainId.SWELL_TESTNET:
        name = 'Swell Testnet'
        break
      case ChainId.ARBITRUM_ONE:
      case ChainId.ARBITRUM:
        name = 'Arbitrum One'
        break
      case ChainId.BASE:
        name = 'Base'
        break
      case ChainId.BLAST:
        name = 'Blast'
        break
      case ChainId.OPTIMISM:
        name = 'Optimism'
        break
      case ChainId.STARKNET:
        name = 'Starknet'
        break
      case ChainId.ZKSYNC:
        name = 'zkSync'
        break
      case ChainId.BERACHAIN:
        name = 'Berachain'
        break
      case ChainId.MONAD:
        name = 'Monad'
        break
      case ChainId.ZIRCUIT_TESTNET:
        name = 'Zircuit Testnet'
        break
      case ChainId.ZIRCUIT:
        name = 'Zircuit'
        break
      case ChainId.FRAXTAL:
        name = 'Fraxtal'
        break
      case ChainId.CORE:
        name = 'Core'
        break
      case ChainId.MANTLE:
        name = 'Mantle'
        break
      case ChainId.MANTLE_TESTNET:
        name = 'Mantle Testnet'
        break
      case ChainId.MODE:
        name = 'Mode'
        break
      case ChainId.MODE_TESTNET:
        name = 'Mode Testnet'
        break
      case ChainId.LISK:
        name = 'Lisk'
        break
      case ChainId.INK:
        name = 'Ink'
        break
      default:
        name = ''
    }
  }
  return name
}
