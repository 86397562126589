import { AirdropResult } from '@/state/dao/types'
import { useEffect, useState } from 'react'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { useLocalStorageBoolean } from '@/hooks/useLocalStorage'
import { Token } from '@/types/tokens'
import {
  makeConsiderDepositRswellInL2Modal,
  makeConsiderRestakingSwellModal,
} from './airdropFormatting'
import { useSwellWeb3 } from '@/swell-web3/core'

export function useAirdropLoading({
  minDuration = 1200,
  airdropResult,
  airdropContractsState,
  airdropOffchainState,
}: {
  minDuration?: number
  airdropResult: object | undefined
  airdropOffchainState: object | undefined
  airdropContractsState: object | undefined
}) {
  const isLoading =
    !airdropResult || !airdropContractsState || !airdropOffchainState

  const [displayIsLoading, setDisplayIsLoading] = useState(isLoading)

  // prevent flickering of loading, display it for a minimum duration
  useEffect(() => {
    if (isLoading) {
      setDisplayIsLoading(true)
    } else {
      const timeout = setTimeout(() => {
        setDisplayIsLoading(false)
      }, minDuration)

      return () => clearTimeout(timeout)
    }
  }, [isLoading, minDuration])

  return isLoading || displayIsLoading
}

export function useCachedClaimAirdropArguments({
  claimAirdrop,
}: {
  claimAirdrop: SwellDaoClaimAirdrop
}) {
  const { account } = useSwellWeb3()

  type CallArgs = Parameters<SwellDaoClaimAirdrop['call']>[0]
  const [args, setArgs] = useState<CallArgs | undefined>()

  useEffect(() => {
    if (
      claimAirdrop.status === claimAirdrop.STATUS.FULFILLED &&
      claimAirdrop.args
    ) {
      setArgs(claimAirdrop.args[0])
    }
  }, [claimAirdrop])

  useEffect(() => {
    setArgs(undefined)
  }, [account])
  return args
}

export const HAS_ACCEPTED_AIRDROP_TERMS_LS_KEY = 'airdrop:hasAcceptedTerms'

export function useHasAcceptedAirdropTerms() {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useLocalStorageBoolean(
    HAS_ACCEPTED_AIRDROP_TERMS_LS_KEY,
    false
  )
  const accept = () => setHasAcceptedTerms(true)
  return {
    hasAcceptedTerms,
    accept,
  }
}

const MODAL_OPEN_DELAY = 640

export function useConsiderRestakingSwellModal({
  airdropResult,
  cachedClaimAirdropArguments,
  daoToken,
}: {
  airdropResult: AirdropResult
  daoToken: Token
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
}) {
  const [open, setOpen] = useState(false)
  function onClose() {
    setOpen(false)
  }

  const summary = makeConsiderRestakingSwellModal({
    airdropResult,
    daoToken,
    cachedClaimAirdropArguments,
  })
  const shouldOpen = Boolean(summary)
  useEffect(() => {
    if (!shouldOpen) {
      return
    }

    const timeout = setTimeout(() => {
      setOpen(true)
    }, MODAL_OPEN_DELAY)
    return () => clearTimeout(timeout)
  }, [shouldOpen])

  return {
    open,
    onClose,
    summary,
  }
}

export function useConsiderL2Modal({
  cachedClaimAirdropArguments,
  airdropResult,
  restakedDaoToken,
  daoToken,
}: {
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
  airdropResult: AirdropResult
  restakedDaoToken: Token
  daoToken: Token
}) {
  const [open, setOpen] = useState(false)
  function onClose() {
    setOpen(false)
  }

  const summary = makeConsiderDepositRswellInL2Modal({
    airdropResult,
    cachedClaimAirdropArguments,
    restakedDaoToken,
    daoToken,
  })
  const shouldOpen = Boolean(summary)
  useEffect(() => {
    if (!shouldOpen) {
      return
    }

    const timeout = setTimeout(() => {
      setOpen(true)
    }, MODAL_OPEN_DELAY)
    return () => clearTimeout(timeout)
  }, [shouldOpen])

  return {
    open,
    onClose,
    summary,
  }
}
