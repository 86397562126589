import { ClaimRswell, SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { AirdropResult } from '@/state/dao/types'
import { Token } from '@/types/tokens'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { merkleClaimable } from '../../util/merkledrop'
import { Web3Toast } from '../StakingConfirmationProgressWidget/Web3Toast'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  CLAIM_AIRDROP_PROMPTING: 'Claim pending',
  CLAIM_AIRDROP_PENDING: 'Claim confirming',

  OPTION_2_PROMPTING: 'Option 2 selection pending',
  OPTION_2_PENDING: 'Option 2 selection confirming',
  COMPLETED_OPTION_2: 'Option 2 selection completed',

  OPTION_1_PROMPTING: 'Option 1 selection pending',
  OPTION_1_PENDING: 'Option 1 selection confirming',
  COMPLETED_OPTION_1: 'Option 1 selection completed',
}

export function ClaimAirdropToast({
  claimAirdrop,
  daoToken,
  airdropResult,
}: {
  claimAirdrop: SwellDaoClaimAirdrop
  daoToken: Token
  airdropResult: AirdropResult | undefined
}) {
  type ToastMode = 'Claim' | 'Lock' | 'ClaimAndLock'
  const ToastMode = {
    Claim: 'Claim' as ToastMode,
    Lock: 'Lock' as ToastMode,
    ClaimAndLock: 'ClaimAndLock' as ToastMode,
  }

  const complete = claimAirdrop.status === claimAirdrop.STATUS.FULFILLED
  const confirming = claimAirdrop.status === claimAirdrop.STATUS.PROMPTING
  const pending = claimAirdrop.status === claimAirdrop.STATUS.PENDING
  const idle = claimAirdrop.status === claimAirdrop.STATUS.IDLE

  const [claimAmount, setClaimAmount] = useState<BigNumber | undefined>()
  useEffect(() => {
    if (idle) {
      setClaimAmount(undefined)
      return
    }

    if (!claimAirdrop.args) return
    if (!airdropResult?.exists) return

    const { claimableAmount } = merkleClaimable({
      cumulativeAmount: airdropResult.data.cumulativeAmount,
      cumulativeClaimed: airdropResult.cumulativeClaimed,
      totalAmount: airdropResult.data.totalAmount,
    })
    setClaimAmount((prev) => {
      if (prev) return prev
      return claimableAmount
    })
  }, [airdropResult, daoToken, claimAirdrop.args, idle])

  let lockStr = ''
  let claimToWalletStr = ''
  let toastMode = ToastMode.Lock
  if (claimAirdrop.args && claimAmount) {
    const [{ amountToLock }] = claimAirdrop.args

    const claimToWalletAmount = claimAmount.sub(amountToLock)

    lockStr = displayCryptoLocale(amountToLock, daoToken.decimals, {
      precision: 2,
      roundLarge: true,
    })
    claimToWalletStr = displayCryptoLocale(
      claimToWalletAmount,
      daoToken.decimals,
      { precision: 2, roundLarge: true }
    )

    if (amountToLock.gt(0) && claimToWalletAmount.gt(0)) {
      toastMode = ToastMode.ClaimAndLock
    } else if (amountToLock.gt(0)) {
      toastMode = ToastMode.Lock
    } else if (claimToWalletAmount.gt(0)) {
      toastMode = ToastMode.Claim
    } else {
      return null
    }
  }

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PENDING
  }

  let message = ''
  if (lockStr && claimToWalletStr) {
    if (toastMode === ToastMode.Lock) {
      message = `Restake ${lockStr} ${daoToken.symbol}`
    } else if (toastMode === ToastMode.Claim) {
      message = `Withdraw ${claimToWalletStr} ${daoToken.symbol}`
    } else if (toastMode === ToastMode.ClaimAndLock) {
      message = `Restake ${lockStr} ${daoToken.symbol} and withdraw ${claimToWalletStr} ${daoToken.symbol}`
    }
  }

  return <Web3Toast title={title} message={message} call={claimAirdrop} />
}

export function ClaimRswellToast({
  airdropResult,
  claimRswell,
  restakedDaoToken,
}: {
  claimRswell: ClaimRswell
  restakedDaoToken: Token
  airdropResult: AirdropResult | undefined
}) {
  const complete = claimRswell.status === claimRswell.STATUS.FULFILLED
  const confirming = claimRswell.status === claimRswell.STATUS.PROMPTING
  const pending = claimRswell.status === claimRswell.STATUS.PENDING
  const idle = claimRswell.status === claimRswell.STATUS.IDLE

  const [claimAmount, setClaimAmount] = useState<BigNumber | undefined>()
  useEffect(() => {
    if (idle) {
      setClaimAmount(undefined)
      return
    }

    if (!claimRswell.args) return
    if (!airdropResult?.exists) return
    if (!airdropResult.rswellAirdrop.exists) return

    const { claimableAmount } = merkleClaimable({
      cumulativeAmount: airdropResult.rswellAirdrop.data.cumulativeAmount,
      cumulativeClaimed: airdropResult.rswellAirdrop.cumulativeClaimed,
      totalAmount: airdropResult.rswellAirdrop.data.totalAmount,
    })
    setClaimAmount((prev) => {
      if (prev) return prev
      return claimableAmount
    })
  }, [airdropResult, claimRswell.args, idle])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_AIRDROP_PENDING
  }

  let message = ''
  if (claimAmount) {
    const claimStr = displayCryptoLocale(
      claimAmount,
      restakedDaoToken.decimals,
      {
        precision: 2,
        roundLarge: true,
      }
    )
    message = `Claim ${claimStr} ${restakedDaoToken.symbol}`
  }

  return <Web3Toast title={title} message={message} call={claimRswell} />
}

// export function Option1Toast({
//   selectOption1,
// }: {
//   selectOption1: SwellDaoSelectOption1
// }) {
//   const complete = selectOption1.status === selectOption1.STATUS.FULFILLED
//   const confirming = selectOption1.status === selectOption1.STATUS.PROMPTING
//   const pending = selectOption1.status === selectOption1.STATUS.PENDING

//   let title = ''
//   if (complete) {
//     title = TRANSACTION_TOAST_TITLE.COMPLETED_OPTION_1
//   } else if (confirming) {
//     title = TRANSACTION_TOAST_TITLE.OPTION_1_PROMPTING
//   } else if (pending) {
//     title = TRANSACTION_TOAST_TITLE.OPTION_1_PENDING
//   }

//   let message = `Selecting option 1`
//   if (complete) {
//     message = `Option 1 selected`
//   }

//   // hide transaction link
//   if (selectOption1.receipt) {
//     selectOption1.txHash = undefined as any
//     selectOption1.receipt.transactionHash = undefined as any
//   }

//   return <Web3Toast title={title} message={message} call={selectOption1} />
// }

// export function Option2Toast({
//   selectOption2,
// }: {
//   selectOption2: SwellDaoSelectOption2
// }) {
//   const complete = selectOption2.status === selectOption2.STATUS.FULFILLED
//   const confirming = selectOption2.status === selectOption2.STATUS.PROMPTING
//   const pending = selectOption2.status === selectOption2.STATUS.PENDING
//   const idle = selectOption2.status === selectOption2.STATUS.IDLE

//   let title = ''
//   if (complete) {
//     title = TRANSACTION_TOAST_TITLE.COMPLETED_OPTION_2
//   } else if (confirming) {
//     title = TRANSACTION_TOAST_TITLE.OPTION_2_PROMPTING
//   } else if (pending) {
//     title = TRANSACTION_TOAST_TITLE.OPTION_2_PENDING
//   }

//   let message = `Selecting option 2`
//   if (complete) {
//     message = `Option 2 selected`
//   }

//   // hide transaction link
//   if (selectOption2.receipt) {
//     selectOption2.txHash = undefined as any
//     selectOption2.receipt.transactionHash = undefined as any
//   }

//   return <Web3Toast title={title} message={message} call={selectOption2} />
// }
