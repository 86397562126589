import React from 'react'
import { BannerL2Launch } from './BannerL2Launch'
import {
  usePredepositStats,
  usePredepositTokens,
  usePredepositUser,
} from '@/state/predeposit/hooks'
import { userPredepositWithdrawableSummary } from './util'
import { BannerClaimPredeposit } from './BannerClaimPredeposit'

export function AppBanner() {
  const { tokenList } = usePredepositTokens()

  const predepositStatsQuery = usePredepositStats()
  const predepositStats = predepositStatsQuery.data
  const predepositUserQuery = usePredepositUser()
  const predepositUser = predepositUserQuery.data

  const summary = userPredepositWithdrawableSummary({
    predepositStats,
    predepositUser,
    thresholdUSD: 1,
    tokens: tokenList.tokens,
  })

  return <BannerClaimPredeposit summary={summary} />
}
