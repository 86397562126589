/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/constants/chains.ts
 * Changed:
 * - Changed the set of supported chains (removed many)
 * - Added hardhat chain
 * - Removed distinction between L1 and L2 chains
 * - Moved `isSupportedChain` to src/util/supportedChainId.ts since it is not constant (environment dependent)
 * - Kept only information about chain names
 */

/**
 * List of all the networks supported by Swell Network
 */
export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  OPTIMISM = 10,
  SWELL = 1923,
  SWELL_TESTNET = 1924,
  HARDHAT = 1337,
  ARBITRUM = 42161,
  SEPOLIA = 11155111,
  OPTIMISM_SEPOLIA = 11155420,
  HOLESKY = 17000,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.HARDHAT]: 'hardhat',
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.SEPOLIA]: 'sepolia',
  [SupportedChainId.HOLESKY]: 'holesky',
  [SupportedChainId.SWELL]: 'swellchain',
  [SupportedChainId.SWELL_TESTNET]: 'swell_testnet',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.ARBITRUM]: 'arbitrum',
  [SupportedChainId.OPTIMISM_SEPOLIA]: 'optimism_sepolia',
}

export const getChainName = (chainId: number) => {
  return CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]
}

export const CHAIN_IDS_TO_TITLE_NAMES = {
  [SupportedChainId.HARDHAT]: 'Hardhat',
  [SupportedChainId.MAINNET]: 'Ethereum Mainnet',
  [SupportedChainId.GOERLI]: 'Goerli Test Network',
  [SupportedChainId.SEPOLIA]: 'Sepolia Test Network',
  [SupportedChainId.HOLESKY]: 'Holesky Test Network',
  [SupportedChainId.SWELL]: 'Swellchain',
  [SupportedChainId.SWELL_TESTNET]: 'Swell Test Network',
  [SupportedChainId.OPTIMISM]: 'Optimism',
  [SupportedChainId.ARBITRUM]: 'Arbitrum',
  [SupportedChainId.OPTIMISM_SEPOLIA]: 'Optimism Sepolia',
}

export const getChainTitleName = (chainId: number) => {
  return CHAIN_IDS_TO_TITLE_NAMES[chainId as SupportedChainId]
}

export const ChainId = {
  MAINNET: SupportedChainId.MAINNET as number,
  GOERLI: SupportedChainId.GOERLI as number,
  HARDHAT: SupportedChainId.HARDHAT as number,
  SEPOLIA: SupportedChainId.SEPOLIA as number,
  HOLESKY: SupportedChainId.HOLESKY as number,
  SWELLCHAIN: SupportedChainId.SWELL as number,
  SWELL_TESTNET: SupportedChainId.SWELL_TESTNET as number,
  OPTIMISM: SupportedChainId.OPTIMISM as number,
  OPTIMISM_SEPOLIA: SupportedChainId.OPTIMISM_SEPOLIA as number,

  // others
  ARBITRUM: SupportedChainId.ARBITRUM as number,
  ARBITRUM_ONE: SupportedChainId.ARBITRUM as number,
  BASE: 8453,
  BLAST: 81457,
  STARKNET: -1,
  ZKSYNC: 324,
  BERACHAIN: -1,
  BERACHAIN_TESTNET: 80085,
  MONAD: -1,
  ZIRCUIT: 48900,
  ZIRCUIT_TESTNET: 48899,
  FRAXTAL: 252,
  CORE: 1116,
  MANTLE: 5000,
  MANTLE_TESTNET: 5001,
  MODE: 34443,
  MODE_TESTNET: 919,
  LISK: 1135,
  INK: 57073,
}
