import { FlexRow } from '@/swell-ui/FlexRow'
import { Tooltip } from '@/swell-ui/Tooltip'
import { Token } from '@/types/tokens'
import React, { useState } from 'react'
import styled from 'styled-components'

const COL_GAP_PX = 15
const TOKEN_SIZE_PX = 32

export function TokenFilterIcons({
  tokens,
  selectedTokens,
  disabledTokens = [],
  setSelectedTokens,
}: {
  tokens: Token[]
  selectedTokens: string[]
  disabledTokens?: string[]
  setSelectedTokens: (tokens: string[]) => void
}) {
  const [hoveredToken, setHoveredToken] = useState<string | null>(null)
  const [justEnabledAll, setJustEnabledAll] = useState(false)

  const selectedSet = new Set(selectedTokens)
  const disabledSet = new Set(disabledTokens)

  const allTokensSelected = selectedTokens.length === tokens.length
  const oneTokenLeft = selectedTokens.length === 1
  const someTokenIsHovered = hoveredToken !== null

  return (
    <FlexRow gap={`${COL_GAP_PX}`} align="center">
      {tokens.map((token) => {
        const selected = selectedSet.has(token.symbol)
        const disabled = disabledSet.has(token.symbol)
        const isHovered = hoveredToken === token.symbol

        let shouldDim = false
        if (isHovered) {
          shouldDim = false
        } else {
          if (someTokenIsHovered) {
            if (selected && allTokensSelected) {
              shouldDim = !justEnabledAll
            } else {
              shouldDim = !selected
            }
          } else {
            shouldDim = !selected
          }
        }

        return (
          <TokenButton
            key={`${token.symbol}-${token.chainId}`}
            token={token}
            selected={!shouldDim}
            onMouseEnter={() => setHoveredToken(token.symbol)}
            onMouseLeave={() => {
              setJustEnabledAll(false)
              setHoveredToken(null)
            }}
            onClick={() => {
              if (disabled) return
              if (!selected) {
                // add to the selection
                setSelectedTokens([...selectedTokens, token.symbol])
                return
              }
              if (oneTokenLeft) {
                // enable everything
                setSelectedTokens(tokens.map((t) => t.symbol))
                setJustEnabledAll(true)
                return
              }
              if (allTokensSelected) {
                // enable just this one
                setSelectedTokens([token.symbol])
                return
              }

              // add to the selection
              setSelectedTokens(
                selectedTokens.filter((t) => t !== token.symbol)
              )
            }}
          />
        )
      })}
    </FlexRow>
  )
}

function TokenButton({
  token,
  selected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  token: Token
  selected: boolean
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}) {
  return (
    <Tooltip title={token.name}>
      <SButton
        aria-selected={selected}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TokenIcon
          src={token.logoURI}
          width={TOKEN_SIZE_PX}
          height={TOKEN_SIZE_PX}
        />
      </SButton>
    </Tooltip>
  )
}

const TokenIcon = styled.img`
  width: ${TOKEN_SIZE_PX}px;
  height: ${TOKEN_SIZE_PX}px;
  border-radius: 50%;
`

const SButton = styled.button`
  width: ${TOKEN_SIZE_PX}px;
  height: ${TOKEN_SIZE_PX}px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  top: 4px;
  position: relative;

  transition: opacity 0.2s;

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.35;
  }

  &[aria-selected='true'] {
    opacity: 1;
  }

  &:not([aria-selected='true']) {
    opacity: 0.35;

    &:hover {
      opacity: 1;
    }
  }
`
