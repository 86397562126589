import { useWavedropsSettings } from '../deployments/hooks/useWavedropsSettings'
import { WavedropsContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  useMerkleDropContract,
  useReadonlyMulticall,
} from '@/hooks/useContract'
import { useMemo } from 'react'
import { CumulativeMerkleDrop__factory, Multicall3 } from '@/abis/types'
import { fetchWavedrops } from './fetcher'
import { StaticWavedropServiceV2 } from '@/services/StaticWavedropV2'
import { MERKLE_DROP_SWELL_WAVEDROPS_MAINNET } from '@/constants/merkleDrops'

const MERKLE_DROP = MERKLE_DROP_SWELL_WAVEDROPS_MAINNET

export function useWavedropsApiImpl(): WavedropsContext {
  const { account: maybeAccount } = useSwellWeb3()
  const { wavedropServiceURL } = useWavedropsSettings()
  const merkleDrop = useMerkleDropContract(MERKLE_DROP.address)!

  const account = maybeAccount!

  const wavedropSvc = useMemo(
    () => new StaticWavedropServiceV2({ baseURL: wavedropServiceURL }),
    [wavedropServiceURL]
  )
  const multicall = useReadonlyMulticall()!

  return {
    currentWaveNumber: 2,
    read: {
      wavedropContractState: async () => {
        const calls: Multicall3.Call3Struct[] = []
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'merkleRoot'
            ),
        })
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'claimIsOpen'
            ),
        })

        const [merkleRootAirdropResult, claimIsOpenAirdropResult] =
          await multicall.callStatic.tryAggregate(true, calls)

        const merkleRoot =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'merkleRoot',
            merkleRootAirdropResult.returnData
          )[0]
        const claimIsOpen =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'claimIsOpen',
            claimIsOpenAirdropResult.returnData
          )[0]

        return {
          merkleDrop: {
            claimIsOpen: claimIsOpen === 1,
            merkleRoot: merkleRoot,
          },
          staking: { exists: false },
        }
      },
      wavedropUser: async () => {
        const cumulativeClaimed = await merkleDrop.cumulativeClaimed(account)
        return { cumulativeClaimedW1: cumulativeClaimed, cumulativeClaimed }
      },
      wavedrops: async () => {
        return fetchWavedrops({ wavedropSvc, account })
      },
      checkClaimWavedrop: async ({ cumulativeAmount, merkleProof }) => {
        return merkleDrop.verifyProof(merkleProof, cumulativeAmount, account)
      },
    },
    write: {
      claimWavedrop: async (
        { amountToLock, cumulativeAmount, merkleProof },
        opts
      ) => {
        return merkleDrop.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof,
          opts
        )
      },
      claimWavedropEstimateGas: async ({
        amountToLock,
        cumulativeAmount,
        merkleProof,
      }) => {
        return merkleDrop.estimateGas.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof
        )
      },
    },
  }
}
