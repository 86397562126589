import styled from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Box } from '@/swell-ui/Box'
import { Tooltip } from '@/swell-ui/Tooltip'
import { PointsCampaign } from '@/types/points'
import { VaultYieldSource } from '@/types/yearnAera'

const ICON_SIZE = 42 // px

const StyledTooltipContainer = styled(Tooltip)`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`
const MAX_YIELD_SOURCES = 3
const MAX_CAMPAIGNS = 5

export function YearnAeraRewards({
  pointsCampaignsList: pointsCampaignsListProp,
  yieldSources: yieldSourcesProp,
}: {
  pointsCampaignsList: PointsCampaign[]
  yieldSources: VaultYieldSource[]
}) {
  const yieldSources = yieldSourcesProp.slice(0, MAX_YIELD_SOURCES)
  const pointsCampaigns = pointsCampaignsListProp.slice(0, MAX_CAMPAIGNS)

  const yieldSourceNodes = yieldSources.map((source) => {
    const soon = !!source.comingSoon

    const img = (
      <img
        key={source.name}
        src={source.logoURI}
        width={ICON_SIZE}
        height={ICON_SIZE}
        className={soon ? 'r-icon soon' : 'r-icon'}
      />
    )

    if (soon) {
      return (
        <StyledTooltipContainer title="Coming soon" key={source.name}>
          {img}
        </StyledTooltipContainer>
      )
    }

    return img
  })

  const campaignNodes = pointsCampaigns.map((campaign) => {
    return (
      <ProtocolPts
        key={campaign.name}
        title={campaign.name}
        multiplier={`${campaign.multiplier}x`}
        logoURI={campaign.logoURI}
        soon={campaign.comingSoon}
      />
    )
  })

  return (
    <Layout gap="24">
      <YieldSourcesBox padding="24px" gap="24">
        <FlexRow direction="column" gap="12" align="center">
          <span className="r-title">Restaking Yield</span>
          <FlexRow gap="12" justify="center">
            {yieldSourceNodes}
          </FlexRow>
        </FlexRow>
      </YieldSourcesBox>
      <CampaignsBox padding="24px">
        <ProtocolsLayout>{campaignNodes}</ProtocolsLayout>
      </CampaignsBox>
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  width: 100%;

  flex-direction: column;
  align-items: center;
  @media (min-width: 580px) {
    align-items: start;
  }
  @media (min-width: 900px) {
    flex-direction: row;
  }

  .r-title {
    white-space: nowrap;

    color: var(--Swell-White-50, #fff);
    /* Body/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .r-multiplier {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }

  .r-icon {
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    &.soon {
      filter: grayscale(100%);
    }
    border-radius: 50%;
  }
`

const BoxBase = styled(Box)`
  overflow: visible;
  border-radius: 16px;
  ${(props) => props.theme.yearnAeraVault.rewardsGlowCSS};
`
const YieldSourcesBox = styled(BoxBase)`
  flex: 0 0 auto;
  width: min-content;
  min-width: 252px;
`
const CampaignsBox = styled(BoxBase)`
  flex: 0 0 auto;
  width: min-content;

  @media (min-width: 1391px) {
    flex: 1 1 auto;
  }
`

const ProtocolsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media (min-width: 580px) {
    display: flex;
    flex-flow: row nowrap;
  }
`

function ProtocolPts({
  logoURI: iconURI,
  multiplier,
  title,
  soon,
}: {
  title: string
  multiplier: string
  logoURI: string
  soon?: boolean
}) {
  let imgClass = 'r-icon'
  if (soon) {
    imgClass += ' soon'
  }

  let imgNode = (
    <img
      src={iconURI}
      width={ICON_SIZE}
      height={ICON_SIZE}
      className={imgClass}
    />
  )
  if (soon) {
    imgNode = (
      <StyledTooltipContainer title="Coming soon">
        {imgNode}
      </StyledTooltipContainer>
    )
  }

  return (
    <FlexRow direction="column" gap={'12'} align="center">
      <span className="r-title">{title}</span>
      <FlexRow gap="12" justify="center">
        <span className="r-multiplier">{multiplier}</span>
        {imgNode}
      </FlexRow>
    </FlexRow>
  )
}
