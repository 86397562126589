import { getAddress } from 'ethers/lib/utils'
import { IStaticAirdropServiceV2, StaticAirdropV2Resp } from './types'
import axios, { AxiosError } from 'axios'
import { makeStaticAirdropV2Result, parseRawStaticAirdropV2Resp } from './util'

export class StaticAirdropServiceV2 implements IStaticAirdropServiceV2 {
  private baseURL: string
  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL?.replace(/\/$/, '')
  }

  private airdropUrl = (address: string) =>
    `${this.baseURL}/api/v2/airdrop?address=${address}&version=250207`

  airdrop: IStaticAirdropServiceV2['airdrop'] = async (address: string) => {
    address = getAddress(address)
    address = address.toLowerCase()
    let data: StaticAirdropV2Resp
    try {
      const res = await axios.get(this.airdropUrl(address))
      data = parseRawStaticAirdropV2Resp(res.data)
    } catch (e: unknown) {
      if (e && e instanceof AxiosError && e.response?.status === 404) {
        return {
          exists: false,
          pearls: 0,
          isSybil: false,
        }
      }

      throw e
    }

    return makeStaticAirdropV2Result(data)
  }
}
