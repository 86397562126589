import { ROUTES } from '@/constants/routes'

export function validateCurrentChainId({
  chainId,
  pathname,
  deploymentChainId,
  l2DeploymentChainId,
  allowTestnet,
  testnetChainIds,
  testnetL2ChainIds,
}: {
  chainId: number
  pathname: string
  deploymentChainId: number
  l2DeploymentChainId: number
  testnetChainIds: number[]
  testnetL2ChainIds: number[]
  allowTestnet: boolean
}) {
  const testnetChainIdSet = new Set(testnetChainIds)
  const testnetL2ChainIdSet = new Set(testnetL2ChainIds)

  const isDeploymentChain = chainId === deploymentChainId
  const isL2DeploymentChain = chainId === l2DeploymentChainId
  const isTestnetChain = testnetChainIdSet.has(chainId)
  const isTestnetL2Chain = testnetL2ChainIdSet.has(chainId)

  let allowL2 = false
  if (pathname.toLowerCase() === ROUTES.SwellchainLaunch.toLowerCase()) {
    allowL2 = true
  }
  if (pathname.toLowerCase() === ROUTES.SwellchainEcosystem.toLowerCase()) {
    allowL2 = true
  }
  if (pathname.toLowerCase() === ROUTES.EarnPortfolio.toLowerCase()) {
    allowL2 = true
  }
  if (pathname.toLowerCase() === ROUTES.EarnBTC.toLowerCase()) {
    allowL2 = true
  }
  if (pathname.toLowerCase() === ROUTES.EarnETH.toLowerCase()) {
    allowL2 = true
  }
  if (pathname.toLowerCase() === ROUTES.EarnWSwell.toLowerCase()) {
    allowL2 = true
  }
  // Swell City page is now only available on mainnet
  // if (pathname.toLowerCase() === ROUTES.DaoSwellCity.toLowerCase()) {
  //   allowL2 = true
  // }

  let ok: boolean
  if (allowTestnet && allowL2) {
    ok =
      isDeploymentChain ||
      isTestnetChain ||
      isL2DeploymentChain ||
      isTestnetL2Chain
  } else if (allowL2) {
    ok = isDeploymentChain || isL2DeploymentChain
  } else if (allowTestnet) {
    ok = isDeploymentChain || isTestnetChain
  } else {
    ok = isDeploymentChain
  }

  return {
    ok,
    isDeploymentChain,
    isL2DeploymentChain,
    isTestnetChain,
    isTestnetL2Chain,
    allowL2,
  }
}
